import { useCallback, useState, useEffect, useMemo } from 'react';
import { Apps, FilterList, ViewList } from '@mui/icons-material';
import {
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  TextField,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidenav from '../../components/Sidenav';
import { FlexBox } from '../../components/flex-box';
import { H5, Paragraph } from '../../components/Typography';
import ShopLayout1 from '../../components/layouts/ShopLayout1';
import ProductCard1List from '../../components/products/ProductCard1List';
import ProductCard9List from '../../components/products/ProductCard9List';
import ProductFilterCard from '../../components/products/ProductFilterCard';
import productsJson from '../../jsons/products.json';
import categoriesJson from '../../jsons/categories.json';
import { useParams } from 'react-router-dom';

const CategoryPage = props => {
  const [merchants, setMerchants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState({});

  useEffect(() => {
    setProducts(productsJson);
    setCategories(categoriesJson.categories);
  }, []);
  const { id } = useParams();

  const productData = useMemo(() => {
    if (Object.keys(products).length) {
      return products[id].product;
    } else {
      return [];
    }
  }, [products, id]);

  console.log('productData', productData);

  const [view, setView] = useState('list');
  const downMd = false;
  const toggleView = useCallback(v => () => setView(v), []);
  const pageResults = {
    totalResults: 10,
    includedResults: 10,
    pagesToRender: 1,
    currentPage: 1,
  };
  const matchedCategory = categories.find(item => item.id == id);

  const handleSort = e => {
    const sortValue = e.target.value;

    // router.push({ query: { ...router.query, sort: sortValue } });
  };

  const handlePageChange = (event, page) => {
    // router.push({ query: { ...router.query, page } });
  };

  return (
    <ShopLayout1 categories={categories} showNavbar={false}>
      <Container
        sx={{
          mt: 4,
          mb: 6,
        }}
      >
        <Card
          elevation={1}
          sx={{
            mb: '55px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: downMd ? 'column' : 'row',
            gap: 3,
            justifyContent: 'space-between',
            p: {
              sm: '1rem 1.25rem',
              md: '0.5rem 1.25rem',
              xs: '1.25rem',
            },
          }}
        >
          <Box display="flex" gap={4} alignItems="center">
            <FlexBox alignItems="center" gap={1} flex="1 1 0">
              {/* <Paragraph color="grey.600" whiteSpace="pre">
                Sort by:
              </Paragraph> */}

              {/* <TextField
                select
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Sort by"
                sx={{
                  flex: '1 1 0',
                  minWidth: '150px',
                }}
                onChange={handleSort}
                value={pageResults.sort}
              >
                {sortOptions.map(item => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField> */}
            </FlexBox>
          </Box>

          <Pagination
            count={1}
            variant="outlined"
            color="primary"
            onChange={handlePageChange}
            page={+1}
          />
        </Card>

        <Grid container spacing={3}>
          <Grid
            item
            md={3}
            sx={{
              display: {
                md: 'block',
                xs: 'none',
              },
            }}
          >
            <ProductFilterCard
              merchants={merchants}
              categories={categories}
              matchedCategory={matchedCategory}
              loading={loading}
            />
          </Grid>
          <Grid item md={9} xs={12}>
            {view === 'grid' ? (
              <ProductCard1List products={productData} results={pageResults} />
            ) : (
              <ProductCard9List products={productData} results={pageResults} />
            )}
          </Grid>
        </Grid>
      </Container>
    </ShopLayout1>
  );
};
const sortOptions = [
  {
    label: 'Price Low to High',
    value: 'price_asc',
  },
  {
    label: 'Price High to Low',
    value: 'price_desc',
  },
];

// get category
// get merchants in that category

export default CategoryPage;
