import { FavoriteBorder } from '@mui/icons-material';
import { Box, Card, Grid, IconButton, styled } from '@mui/material';
import Image from '../BazaarImage';
import { H5 } from '../Typography';
import { FlexBox } from '../flex-box';
// styled components
const Wrapper = styled(Card)({
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  marginBottom: '1.25rem',
});

// ===========================================================

// ===========================================================

const ProductCard9 = ({ product }) => {
  const { id, title, type } = product;

  const isOffer = type.toLowerCase() === 'offer';

  const offerUrl = product.url.value;
  const merchantImage = product.merchantLogoUrl;
  const merchantName = product.merchantName;

  const minPrice = product.priceSet
    ? product.priceSet.minPrice.value
    : product.price.value;
  const maxPrice = product.priceSet
    ? product.priceSet.maxPrice.value
    : product.price.value;

  const productImages = product.images.image;
  const thumbnail = productImages[productImages.length - 1].value;
  const productLink = isOffer ? offerUrl : `/product/${id}`;

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <Grid item sm={3} xs={12}>
          <Box position="relative">
            <a href={productLink}>
              <Image src={thumbnail} alt={title} width="100%" />
            </a>
          </Box>
        </Grid>

        <Grid item sm={9} xs={12}>
          <FlexBox
            flexDirection="column"
            justifyContent="center"
            height="100%"
            p={2}
          >
            <a href={productLink}>
              <H5 fontWeight="600" my="0.5rem">
                {title}
              </H5>
            </a>

            <FlexBox mt={1} mb={2} gap={4} alignItems="center">
              {isOffer && (
                <a href={productLink} rel="noopener">
                  <img
                    src={merchantImage}
                    height={20}
                    alt={merchantName}
                    style={{ cursor: 'pointer' }}
                  />
                </a>
              )}
              {!isOffer && (
                <Box color="grey.600">
                  Min{' '}
                  <Box color="primary.main" component="span" fontWeight="500">
                    {minPrice}
                  </Box>
                </Box>
              )}
              <Box color="grey.600">
                Max{' '}
                <Box color="primary.main" component="span" fontWeight="500">
                  {maxPrice}
                </Box>
              </Box>
            </FlexBox>

            <FlexBox></FlexBox>
          </FlexBox>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ProductCard9;
