import { Box, Grid, Paper } from '@mui/material';
import LazyImage from '../../components/LazyImage';
import { H1, H5, Paragraph } from '../../components/Typography';
import { FlexBox } from '../flex-box';

// ================================================================

// ================================================================

const ProductIntroOffer = ({ product }) => {
  const imageToShow = product.images ? product.images[0].value : '';
  return (
    <Box width="100%">
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item md={6} xs={12} alignItems="center">
          <FlexBox justifyContent="center" mb={6}>
            <LazyImage
              alt={product.title}
              width={300}
              height={300}
              loading="eager"
              objectFit="contain"
              src={imageToShow}
            />
          </FlexBox>
        </Grid>

        <Grid item md={6} xs={12} alignItems="center">
          <H1 mb={1}>{product.title}</H1>
          <Paragraph mb={3}>
            {product.description ?? 'No description available'}
          </Paragraph>
          <Box>
            <Grid container spacing={2} my={1}>
              <Grid item xs={12} sm={6} md={12} lg={6} alignItems="stretch">
                <a target="_blank" href={product.url} rel="noopener">
                  <Paper
                    component="div"
                    sx={{ height: '100%', cursor: 'pointer' }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      sx={{ height: '100%' }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p={1}
                        flex="1"
                      ></Box>
                      <Box
                        p={1}
                        flex="1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Paragraph fontWeight={700} color="primary.main">
                          {product.price}
                        </Paragraph>
                      </Box>
                    </Box>
                  </Paper>
                </a>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProductIntroOffer;
