import { Box, styled } from '@mui/material';
import CategoryMenuItem from './CategoryMenuItem';
import { CategoryRounded } from '@mui/icons-material';

// styled component
const Wrapper = styled(Box)(({ theme, position, open }) => ({
  left: 0,
  zIndex: 98,
  right: 'auto',
  borderRadius: 4,
  padding: '0.5rem 0px',
  transformOrigin: 'top',
  boxShadow: theme.shadows[2],
  position: position || 'unset',
  transition: 'all 250ms ease-in-out',
  transform: open ? 'scaleY(1)' : 'scaleY(0)',
  backgroundColor: theme.palette.background.paper,
  top: position === 'absolute' ? 'calc(100% + 0.7rem)' : '0.5rem',
}));

// ===============================================================

// ===============================================================

const CategoryMenuCard = props => {
  const { open, position } = props;
  const categories = props.categories ?? [];
  console.log('categories', categories);

  return (
    <Wrapper open={open} position={position}>
      {categories.map(item => {
        return (
          <CategoryMenuItem
            key={item.title}
            href={item.href}
            icon={item.icon}
            title={item.title}
            caret={!!item.menuData}
          />
        );
      })}
    </Wrapper>
  );
};
CategoryMenuCard.defaultProps = {
  position: 'absolute',
};
export default CategoryMenuCard;
