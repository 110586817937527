import ShoppingPage from './pages/shopping';
import CssBaseline from '@mui/material/CssBaseline';

import './index.css';

function App() {
  return (
    <>
      <CssBaseline />
      <ShoppingPage />
    </>
  );
}

export default App;
