import { Box, useTheme, Grid, Container } from '@mui/material';

import ShopLayout1 from '../../components/layouts/ShopLayout1';
import Section2 from '../../pages-sections/Section2';
import Section7 from '../../pages-sections/Section7';
import CatalogService from '../../utils/api/catalog';
import CategoryCard1 from '../../components/category-cards/CategoryCard1';
import { H2 } from '../../components/Typography';
import { useEffect, useState } from 'react';
import productsJson from '../../jsons/products.json';
import categoriesjson from '../../jsons/categories.json';

const ShoppingPage = props => {
  const theme = useTheme();
  const [data, setData] = useState();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState({});

  useEffect(() => {
    setProducts(productsJson);
    setCategories(categoriesjson.categories);
  }, []);
  const firstCategoryLink = categories?.length > 0 ? categories[0].href : '';
  console.log(products);
  return (
    <>
      <ShopLayout1
        topbarBgColor={theme.palette.grey[900]}
        categories={categories}
        showNavbar={false}
      >
        <Box bgcolor="white">
          <Section7 link={firstCategoryLink} />
          <Section2 serviceList={serviceList} />
          <Container sx={{ my: 6 }}>
            <H2 textAlign="center" mb={4}>
              Popular Categories
            </H2>
            <Grid container spacing={3}>
              <Grid item md={3} sm={6} xs={12}>
                <CategoryCard1
                  image="/assets/images/computer.jpg"
                  title="Computers and Software"
                  href="/category/4"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CategoryCard1
                  image="/assets/images/pet.jpg"
                  title="Pet supplies"
                  href="/category/31000000"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CategoryCard1
                  image="/assets/images/clothing.jpg"
                  title="Clothing &amp; Accessories"
                  href="/category/10000000"
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CategoryCard1
                  image="/assets/images/sports.jpg"
                  title="Sports Equipment &amp; Outdoor Gear"
                  href="/category/12000000"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </ShopLayout1>
    </>
  );
};

const serviceList = [
  {
    title: 'Shop smarter',
    description: 'Compare prices from different stores',
    icon: 'CartBag',
  },
  {
    title: 'Endless options',
    description: 'Variety of products to choose from',
    icon: 'Category',
  },
  {
    title: 'Made for you',
    description: 'Smoother shopping experience for customers',
    icon: 'User',
  },
];

export default ShoppingPage;
