import { styled } from '@mui/material';
import clsx from 'clsx';

// styled component
const StyledLink = styled('a')(({ theme, active_route }) => ({
  position: 'relative',
  transition: 'color 150ms ease-in-out',
  color: active_route === 'active' ? theme.palette.primary.main : 'inherit',
  '&:hover': {
    color: `${theme.palette.primary.main} !important`,
  },
}));
const NavLink = ({ href, children, style, className, ...props }) => {
  const currentRoute = 'active';
  return (
    <a href={href}>
      <StyledLink
        href={href}
        style={style}
        className={clsx(className)}
        active_route={currentRoute ? 'active' : ''}
        {...props}
      >
        {children}
      </StyledLink>
    </a>
  );
};
export default NavLink;
