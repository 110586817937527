import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';

import AuthProvider from './context/authContext';

import RedirectPage from './pages/redirect';
import NotFoundPage from './pages/404';
import CategoryPage from './pages/category';
import ProductsPage from './pages/products';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms-and-conditions';
import FacebookRedirectPage from './pages/facebookRedirect';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [],
  },
  {
    path: '/fb',
    element: <FacebookRedirectPage />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsPage />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPage />,
  },
  {
    path: '/category/:id',
    element: <CategoryPage />,
  },
  {
    path: '/product/:id',
    element: <ProductsPage />,
  },
  {
    path: '/r/:id',
    element: <RedirectPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/404',
    element: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
