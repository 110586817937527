import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Rating,
  TextField,
} from '@mui/material';
import Accordion from '../accordion/Accordion';
import { FlexBetween } from '../flex-box';
import { H5, H6, Paragraph, Span } from '../Typography';
import AccordionHeader from '../accordion/AccordionHeader';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../Scrollbar';

const ProductFilterCard = ({
  categories = [],
  matchedCategory,
  merchants = [],
  loading = false,
}) => {
  // const merchantParam = router.query.m;
  // const merchantParamArray = merchantParam ? merchantParam.split(',') : [];
  const navigate = useNavigate();
  const navigateToCategory = id => {
    navigate(`/category/${id}`);
  };

  const filterMerchant = el => {
    const {
      target: { checked, value },
    } = el;

    // const newMerchantParam = checked
    //   ? merchantParamArray.concat(value).join(',')
    //   : merchantParamArray.filter(item => item != value).join(',');

    // router.push({ query: { ...router.query, page: 1, m: newMerchantParam } });
  };

  return (
    <Card
      sx={{
        p: '18px 27px',
        overflow: 'auto',
      }}
      elevation={1}
    >
      <H6 mb={1.25}>Categories</H6>

      {categories.map(item => {
        const isMatchedCategory = matchedCategory.id == item.id;

        if (item.subCategories)
          return (
            <Accordion key={item.title} expanded>
              <AccordionHeader px={0} py={0.75} color="grey.600">
                <Span
                  sx={{
                    cursor: 'pointer',
                    mr: '9px',
                  }}
                >
                  {item.title}
                </Span>
              </AccordionHeader>

              {item.subCategories.map(name => (
                <Paragraph
                  pl="22px"
                  py={0.75}
                  key={name}
                  fontSize="14px"
                  color="grey.600"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  {name}
                </Paragraph>
              ))}
            </Accordion>
          );

        return (
          <Paragraph
            py={0.75}
            fontSize="14px"
            color={isMatchedCategory ? 'primary.main' : 'grey.600'}
            key={item.title}
            fontWeight={isMatchedCategory ? '500' : '400'}
            sx={{ cursor: 'pointer' }}
            onClick={() => navigateToCategory(item.id)}
          >
            {item.title}
          </Paragraph>
        );
      })}

      <Divider
        sx={{
          mt: 2,
          mb: 3,
        }}
      />

      {/* PRICE VARIANT FILTER 
      <FlexBetween>
        <TextField placeholder="0" type="number" size="small" fullWidth />
        <H5 color="grey.600" px={1}>
          -
        </H5>
        <TextField placeholder="250" type="number" size="small" fullWidth />
      </FlexBetween>
      */}
    </Card>
  );
};

export default ProductFilterCard;
