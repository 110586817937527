import { Button } from '@mui/material';
import { FlexRowCenter } from '../components/flex-box';
import { H1, H3, Paragraph, Span } from '../components/Typography';

const Section7 = ({ link }) => {
  return (
    <FlexRowCenter
      mt={8}
      flexDirection="column"
      sx={{
        color: '#fff',
        textAlign: 'center',
        padding: '6rem 2rem',
        backgroundSize: 'cover',
        backgroundColor: 'grey',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(/assets/images/hero.jpg)',
      }}
    >
      <H3 fontWeight={400} fontSize={30} lineHeight={1}>
        Looking for <Span color="#D23F57">something?</Span>
      </H3>

      <H1 fontSize={50} lineHeight={1} mb={1}>
        Browse our endless collection
      </H1>

      <Paragraph fontWeight={600} fontSize={18} mb={4}>
        Cheaper and easier options just for you
      </Paragraph>

      <Button variant="contained" size="large" color="primary">
        <a href={link}>Shop Now</a>
      </Button>
    </FlexRowCenter>
  );
};
export default Section7;
