import Home from '../icons/Home';
import useWindowSize from '../../hooks/useWindowSize';
import { iconStyle, StyledNavLink, Wrapper } from './styles';

const MobileNavigationBar = () => {
  const width = useWindowSize();
  return width <= 900 ? (
    <Wrapper>
      {list.map(item => (
        <StyledNavLink href={item.href} key={item.title}>
          <item.icon sx={iconStyle} fontSize="small" />
          {item.title}
        </StyledNavLink>
      ))}
    </Wrapper>
  ) : null;
};
const list = [
  {
    title: 'Home',
    icon: Home,
    href: '/',
  },
];
export default MobileNavigationBar;
