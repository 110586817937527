import { Container, styled, Tabs } from '@mui/material';
import { H2 } from '../../components/Typography';
import ShopLayout1 from '../../components/layouts/ShopLayout1';
import ProductIntroOffer from '../../components/products/ProductIntroOffer';
import productsJson from '../../jsons/products.json';
import categoriesjson from '../../jsons/categories.json';
import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
// styled component
const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 0,
  marginTop: 80,
  marginBottom: 24,
  borderBottom: `1px solid ${theme.palette.text.disabled}`,
  '& .inner-tab': {
    minHeight: 40,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
}));

const ProductsPage = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState({});
  const { id } = useParams();
  useEffect(() => {
    setProducts(productsJson);
    setCategories(categoriesjson.categories);
  }, []);

  const product = useMemo(() => {
    if (Object.keys(products).length !== 0) {
      console.log('im in wtf');
      const test = Object.keys(products)
        .map(e => {
          const res = products[e].product.find(c => c.id == id);
          return res;
        })
        .filter(x => x !== undefined);
      console.log('test', test);
      if (test.length > 0) {
        const res2 = {
          title: test[0].title,
          description: test[0].description,
          images: test[0].images.image,
          url: test[0].url.value,
          price: test[0].priceSet.minPrice.value,
        };
        return res2;
      } else {
        return {};
      }
    } else return {};
  }, [products]);

  console.log('productszx', product);
  console.log('json', products);

  return (
    <ShopLayout1 categories={categories} showNavbar={false}>
      <Container
        sx={{
          my: 4,
        }}
      >
        {product ? (
          <ProductIntroOffer product={product} />
        ) : (
          <H2>Loading...</H2>
        )}
      </Container>
    </ShopLayout1>
  );
};

export default ProductsPage;
