import { Fragment, useState } from 'react';
import { Clear, ExpandMore, Menu } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Box,
  Drawer,
  IconButton,
} from '@mui/material';
import { H6 } from '../Typography';
import Scrollbar from '../Scrollbar';
import { NavLink } from '../nav-link';

const MobileMenu = ({ categories }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Fragment>
      <IconButton
        onClick={() => setOpenDrawer(true)}
        sx={{
          flexShrink: 0,
          color: 'grey.600',
        }}
      >
        <Menu />
      </IconButton>

      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{
          zIndex: 15001,
        }}
      >
        <Box
          sx={{
            width: '100vw',
            height: '100%',
            position: 'relative',
          }}
        >
          <Scrollbar
            autoHide={false}
            sx={{
              height: '100vh',
            }}
          >
            <Box
              maxWidth={500}
              margin="auto"
              position="relative"
              height="100%"
              px={5}
              py={8}
            >
              <IconButton
                onClick={() => setOpenDrawer(false)}
                sx={{
                  position: 'absolute',
                  right: 30,
                  top: 15,
                }}
              >
                <Clear fontSize="small" />
              </IconButton>

              {/* {renderLevels(updateNavigations)} */}
              <H6 py={1}>
                <NavLink href="/">Home</NavLink>
              </H6>

              <Accordion
                square
                elevation={0}
                disableGutters
                sx={{
                  '&:not(:last-child)': {
                    borderBottom: 0,
                  },
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    padding: 0,
                    minHeight: 48,
                    boxShadow: 'none',
                    '& .Mui-expanded': {
                      color: 'primary.main',
                      margin: 0,
                    },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                      margin: 0,
                      '& .MuiSvgIcon-root': {
                        color: 'primary.main',
                      },
                    },
                  }}
                >
                  <H6>Categories</H6>
                </AccordionSummary>
                <Box mx={2}>
                  {categories.map(item => {
                    return (
                      <Box key={item.id} py={1}>
                        <NavLink href={item.href}>{item.title}</NavLink>
                      </Box>
                    );
                  })}
                </Box>
              </Accordion>
              <Accordion
                square
                elevation={0}
                disableGutters
                sx={{
                  '&:not(:last-child)': {
                    borderBottom: 0,
                  },
                  '&:before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    padding: 0,
                    minHeight: 48,
                    boxShadow: 'none',
                    '& .Mui-expanded': {
                      color: 'primary.main',
                      margin: 0,
                    },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                      margin: 0,
                      '& .MuiSvgIcon-root': {
                        color: 'primary.main',
                      },
                    },
                  }}
                >
                  <H6>Other pages</H6>
                </AccordionSummary>
                <Box mx={2}>
                  <Box py={1}>
                    <NavLink href="/privacy-policy">Privacy Policy</NavLink>
                  </Box>
                  <Box py={1}>
                    <NavLink href="/terms-and-conditions">
                      Terms and Conditions
                    </NavLink>
                  </Box>
                </Box>
              </Accordion>
            </Box>
          </Scrollbar>
        </Box>
      </Drawer>
    </Fragment>
  );
};
export default MobileMenu;
