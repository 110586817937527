import { Fragment, useCallback, useState } from 'react';
import Sticky from '../Sticky';
import Topbar from '../Topbar';
import { Footer1 } from '../footer';
import Header from '../header/Header';
import { MobileNavigationBar } from '../mobile-navigation';
import SearchInputWithCategory from '../search-box/SearchInputWithCategory';
import CssBaseline from '@mui/material/CssBaseline';

const ShopLayout1 = ({
  children,
  topbarBgColor,
  showTopbar = true,
  showNavbar = true,
  categories,
  hideCategories = false,
  hideSearch = false,
}) => {
  const [isFixed, setIsFixed] = useState(false);
  const toggleIsFixed = useCallback(fixed => setIsFixed(fixed), []);

  return (
    <Fragment>
      <CssBaseline />
      {showTopbar && <Topbar bgColor={topbarBgColor} />}
      <Sticky fixedOn={0} onSticky={toggleIsFixed} scrollDistance={300}>
        <Header
          isFixed={isFixed}
          // searchInput={<SearchInputWithCategory categories={categories} />}
          categories={categories}
          hideCategories={hideCategories}
          hideSearch={hideSearch}
        />
      </Sticky>
      <div className="section-after-sticky">{children}</div>
      <MobileNavigationBar />
      <Footer1 />
    </Fragment>
  );
};
export default ShopLayout1;
