import catalogApi from './_axios';

export default class CatalogService {
  static publisherId = process.env.PUBLISHER_ID;
  static apiKey = process.env.API_KEY;

  static async _parseCategories() {}

  static _sortProducts(a, b) {
    const aPrice = CatalogService._getProductPrice(a);
    const bPrice = CatalogService._getProductPrice(b);
    return aPrice - bPrice;
  }

  static _getProductPrice(item) {
    const isOffer = item.type.toLowerCase() === 'offer';

    if (isOffer) {
      const {
        price: { integral },
      } = item;
      return integral;
    }

    const {
      priceSet: {
        minPrice: { integral: integralMin },
        maxPrice: { integral: integralMax },
      },
    } = item;

    return Math.floor((integralMin + integralMax) / 2);
  }

  static async sendGetRequest(path, additionalParams = {}) {
    const publisherId = CatalogService.publisherId;
    const apiKey = CatalogService.apiKey;

    const params = {
      publisherId,
      apiKey,
      format: 'json',
      ...additionalParams,
    };

    return await catalogApi.get(path, {
      params,
    });
  }

  static async getCategories() {
    const res = await CatalogService.sendGetRequest('/taxonomy');

    const rootCategory = res.data.taxonomy.categories.category.find(
      item => item.id === 1
    );

    const categories = rootCategory.children.category.map(item => {
      const { children, ...rest } = item;

      return rest;
    });

    const filtredCategories = categories.filter(item => !item.mature);

    return filtredCategories;
  }

  static async getMerchants() {
    const res = await CatalogService.sendGetRequest('/merchantinfo', {
      activeOnly: true,
    });
    return res.data;
  }

  static async getProductsByCategory(categoryId, options) {
    const { startPage, pageSize, sort, merchantId, offerCount } = options;
    if (Array.isArray(merchantId)) {
      let resultCount = 0;
      let resultProducts = [];

      const start = startPage * pageSize;
      const end = start + pageSize;

      for (const merchant of merchantId) {
        const merchantRes = await this.getProductsByCategory(categoryId, {
          ...options,
          startPage: 0,
          pageSize: end,
          merchantId: merchant,
        });

        resultCount += merchantRes.products.totalResults;
        resultProducts = resultProducts.concat(merchantRes.products.product);
      }

      resultProducts.sort(CatalogService._sortProducts);

      return {
        products: {
          product: resultProducts.slice(start, end),
          totalResults: resultCount,
          includedResults: pageSize,
        },
      };
    }

    const res = await CatalogService.sendGetRequest('/product', {
      categoryId,
      start: startPage * pageSize,
      results: pageSize,
      sort,
      merchantId,
      resultsOffers: offerCount,
    });

    return res.data;
  }

  static async getProductsByKeyword(keyword, categoryId) {
    const params = {
      keyword,
    };

    if (categoryId) params['categoryId'] = categoryId;

    const res = await CatalogService.sendGetRequest('/product', params);

    return res.data;
  }

  static async getProductById(productId) {
    const res = await CatalogService.sendGetRequest('/product', {
      productId,
      productIdType: 'SZPID',
      resultsOffers: 10,
    });

    return res.data;
  }

  static async getOfferById(productId, merchantId) {
    const res = await CatalogService.sendGetRequest('/product', {
      productId,
      merchantId,
      productIdType: 'MPID',
    });

    return res.data;
  }

  static async searchProduct({ startPage, pageSize, sort, keyword, category }) {
    const params = {
      keyword,
      start: startPage,
      results: pageSize,
      sort,
    };

    if (category) params['categoryId'] = category;

    const res = await CatalogService.sendGetRequest('/product', params);

    return res.data;
  }

  static async getMerchants() {
    const params = {
      activeOnly: true,
    };

    const res = await CatalogService.sendGetRequest('/merchantinfo', params);

    return res.data;
  }
}
