import { Box, Chip, styled } from '@mui/material';
import LazyImage from '../LazyImage';
import BazaarCard from '../BazaarCard';
import { H3 } from '../Typography';
import { FlexBox } from '../flex-box';

// styled components
const StyledBazaarCard = styled(BazaarCard)({
  height: '100%',
  margin: 'auto',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'all 250ms ease-in-out',
  ':hover': {
    '& .hover-box': {
      opacity: 1,
    },
  },
});
const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  position: 'relative',
  display: 'inline-block',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
  cursor: 'pointer',
}));
const ContentWrapper = styled(Box)({
  padding: '1rem',
  '& .title, & .categories': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cursor: 'pointer',
});

const CustomLink = ({ isOffer, children, ...props }) => {
  if (isOffer)
    return (
      <a {...props} rel="noopenner">
        {children}
      </a>
    );

  return <a {...props}>{children}</a>;
};

const ProductCard1 = ({ product }) => {
  const { id, title, type } = product;

  const isOffer = type.toLowerCase() === 'offer';

  const offerUrl = product.url.value;
  const merchantImage = product.merchantLogoUrl;
  const merchantName = product.merchantName;

  const minPrice = product.priceSet
    ? product.priceSet.minPrice.value
    : product.price.value;
  const maxPrice = product.priceSet
    ? product.priceSet.maxPrice.value
    : product.price.value;

  const productImages = product.images.image;
  const thumbnail = productImages[productImages.length - 1].value;
  const productLink = isOffer ? offerUrl : `/product/${id}`;

  return (
    <StyledBazaarCard hoverEffect={true}>
      <ImageWrapper>
        <CustomLink href={productLink} isOffer={isOffer} alt={title}>
          <LazyImage
            src={thumbnail}
            width={0}
            height={0}
            layout="responsive"
            alt={title}
          />
        </CustomLink>
      </ImageWrapper>

      <ContentWrapper>
        <FlexBox>
          <Box flex="1 1 0" minWidth="0px" mr={1}>
            <Box mb={0.5}>
              <CustomLink href={productLink} isOffer={isOffer} alt={title}>
                <H3
                  mb={1}
                  title={title}
                  fontSize="14px"
                  fontWeight="600"
                  className="title"
                  color="text.secondary"
                >
                  {title}
                </H3>
              </CustomLink>
            </Box>

            <FlexBox alignItems="center" gap={3}>
              {isOffer && (
                <CustomLink href={productLink} isOffer={isOffer} alt={title}>
                  <img
                    src={merchantImage}
                    height={20}
                    alt={merchantName}
                    style={{ cursor: 'pointer' }}
                  />
                </CustomLink>
              )}
              {!isOffer && (
                <Box color="grey.600">
                  Min{' '}
                  <Box color="primary.main" component="span" fontWeight="500">
                    {minPrice}
                  </Box>
                </Box>
              )}
              <Box color="grey.600">
                Max{' '}
                <Box color="primary.main" component="span" fontWeight="500">
                  {maxPrice}
                </Box>
              </Box>
            </FlexBox>
          </Box>
        </FlexBox>
      </ContentWrapper>
    </StyledBazaarCard>
  );
};
export default ProductCard1;
