import { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Box, Container, MenuItem, styled } from '@mui/material';
import TouchRipple from '@mui/material/ButtonBase';
import { Span } from '../components/Typography';
import { FlexBox } from '../components/flex-box';
import BazaarMenu from '../components/BazaarMenu';
import { layoutConstant } from '../utils/constants';

// styled component
const TopbarWrapper = styled(Box, {
  shouldForwardProp: props => props !== 'bgColor',
})(({ theme, bgColor }) => ({
  fontSize: 12,
  height: layoutConstant.topbarHeight,
  color: theme.palette.secondary.contrastText,
  background: bgColor || theme.palette.grey[900],
  transition: 'height 300ms ease',
  '& .menuItem': {
    minWidth: 100,
  },
  '& .marginRight': {
    marginRight: '1.25rem',
  },
  '& .expand': {
    display: 'none',
    padding: 0,
  },
  '& .handler': {
    height: layoutConstant.topbarHeight,
  },
  '& .menuTitle': {
    fontSize: 12,
    marginLeft: '0.5rem',
    fontWeight: 600,
  },
  [theme.breakpoints.down('sm')]: {
    '& .topbarRight': {
      display: 'flex',
      paddingBottom: 5,
    },
    '& .expand': {
      display: 'block',
      height: layoutConstant.topbarHeight,
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
}));
const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

// ===========================================

// ===========================================

const Topbar = ({ bgColor }) => {
  const [expand, setExpand] = useState(true);
  const [language, setLanguage] = useState('en');

  return (
    <TopbarWrapper bgColor={bgColor} expand={expand ? 1 : 0}>
      <StyledContainer>
        <FlexBox
          className="topbarRight"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
        >
          <BazaarMenu
            handler={
              <TouchRipple className="handler marginRight">
                <Span className="menuTitle">
                  {language === 'en' ? 'EN' : 'DE'}
                </Span>
                <ExpandMore fontSize="inherit" />
              </TouchRipple>
            }
          >
            {languageList.map(item => (
              <MenuItem key={item.title} className="menuItem">
                <Span className="menuTitle">{item.title}</Span>
              </MenuItem>
            ))}
          </BazaarMenu>
        </FlexBox>
      </StyledContainer>
    </TopbarWrapper>
  );
};

const languageList = [
  {
    title: 'EN',
    value: 'en',
  },
];
export default Topbar;
