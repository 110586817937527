import { Box, Container, Grid, styled } from '@mui/material';
import Image from '../BazaarImage';
import { Paragraph } from '../Typography';

// styled component
const StyledLink = styled('a')(({ theme }) => ({
  display: 'block',
  borderRadius: 4,
  cursor: 'pointer',
  position: 'relative',
  padding: '0.3rem 0rem',
  color: theme.palette.grey[500],
  '&:hover': {
    color: theme.palette.grey[100],
  },
}));
const Footer1 = () => {
  return (
    <footer>
      <Box bgcolor="#222935">
        <Container
          sx={{
            p: '1rem',
            color: 'white',
          }}
        >
          <Box py={10} overflow="hidden">
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <Paragraph mb={2.5} color="grey.500">
                  Our bazaar is also a great place to find gifts for loved ones,
                  with a variety of options that will suit any taste or
                  occasion. You can browse by category, and compare prices from
                  different stores, making it easy to find exactly what you're
                  looking for.
                </Paragraph>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box
                  fontSize="18px"
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Pages
                </Box>
                <div>
                  <a href="/terms-and-conditions">
                    <StyledLink>Terms and Conditions</StyledLink>
                  </a>
                  <a href="/privacy-policy">
                    <StyledLink>Privacy Policy</StyledLink>
                  </a>
                </div>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Box
                  fontSize="18px"
                  fontWeight="600"
                  mb={1.5}
                  lineHeight="1"
                  color="white"
                >
                  Contact Us
                </Box>

                <Box py={0.6} color="grey.500">
                  Inter Media Digital Marketing LLC Office No. 306-0102 - King
                  Khalid Abdullah Qassem Al Qassim Dubai Investment Park 1 Dubai
                  United Arab Emirates
                </Box>

                <Box py={0.6} color="grey.500">
                  Email: support@aka-extensions.com
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
export default Footer1;
