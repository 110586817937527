import { Box, useTheme, Container } from '@mui/material';

import ShopLayout1 from '../../components/layouts/ShopLayout1';
import { H1, H3, Paragraph } from '../../components/Typography';

const TermsPage = () => {
  const theme = useTheme();

  return (
    <ShopLayout1
      topbarBgColor={theme.palette.grey[900]}
      showNavbar={false}
      hideSearch
      hideCategories
    >
      <Box bgcolor="white" py={4}>
        <Container>
          <H1 mb={3}>TERMS &amp; CONDITIONS</H1>
          <Paragraph mb={2}>
            <H3>Content</H3> The content of the pages of Inter Media Digital
            Marketing LLC.co is for your general information and use only. It is
            subject to change without notice.
          </Paragraph>
          <Paragraph mb={2}>
            <H3>Warranty</H3> Neither we nor any third parties provide any
            warranty or guarantee as to the accuracy, timeliness, performance,
            completeness or suitability of the information and materials found
            or offered on Inter Media Digital Marketing LLC.co for any
            particular purpose. You acknowledge that such information and
            materials may contain inaccuracies or errors and we expressly
            exclude liability for any such inaccuracies or errors to the fullest
            extent permitted by law.
          </Paragraph>
          <Paragraph mb={2}>
            <H3>Info &amp; Materials</H3>
            Your use of any information or materials on Inter Media Digital
            Marketing LLC.co is entirely at your own risk, for which we shall
            not be liable. It shall be your own responsibility to ensure that
            any products, services or information available through Inter Media
            Digital Marketing LLC.co meet your specific requirements.
          </Paragraph>
          <Paragraph mb={2}>
            <H3> License</H3>
            Inter Media Digital Marketing LLC.co contains material which is
            owned by or licensed to us. This material includes, but is not
            limited to, the design, layout, look, appearance and graphics.
            Reproduction is prohibited other than in accordance with US and
            international copyright laws.
          </Paragraph>
          <Paragraph mb={2}>
            <H3>Trademarks</H3>
            All trademarks reproduced in Inter Media Digital Marketing LLC.co,
            which are not the property of, or licensed to the operator, are
            acknowledged on the website
          </Paragraph>
          <Paragraph mb={2}>
            <H3>Unauthorized Use</H3>
            Unauthorized use of Inter Media Digital Marketing LLC.co may give
            rise to a claim for damages and/or be a criminal offense depending
            on your jurisdiction.
          </Paragraph>
          <Paragraph mb={2}>
            <H3>Other websites</H3>
            From time to time Inter Media Digital Marketing LLC.co may also
            include links to other websites. These links are provided for your
            convenience to provide further information. They do not signify that
            we endorse the website(s). We have no responsibility for the content
            of the linked website(s).
          </Paragraph>
          <Paragraph mb={4}>
            <H3>Use</H3>
            Your use of Inter Media Digital Marketing LLC.co and any dispute
            arising out of such use of the website is subject to the laws of the
            state of California.
          </Paragraph>
          <Paragraph>
            Inter Media Digital Marketing LLC Office No. 306-0102 - King Khalid
            Abdullah Qassem Al Qassim Dubai Investment Park 1 Dubai United Arab
            Emirates
          </Paragraph>
        </Container>
      </Box>
    </ShopLayout1>
  );
};

export default TermsPage;
