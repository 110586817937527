import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LinearProgress, Typography, Box } from '@mui/material';
import useApi from '../../hooks/useApi';

function RedirectPage() {
  const { id } = useParams();
  const { api: apiClient } = useApi();
  const [queries, setQueries] = useSearchParams();

  const count = useRef(0);

  const navigate = useNavigate();

  const getOfferRedirectLink = async redirectId => {
    const queriesObject = Object.fromEntries(queries.entries());
    const {
      data: { redirect },
    } = await apiClient.post(`/api/redirect`, {
      redirectId,
      query: queriesObject,
      visitedUrl: window.location.toString(),
    });

    return redirect;
  };

  const onMount = async id => {
    try {
      const url = await getOfferRedirectLink(id);

      if (!url) throw new Error();

      window.location.href = url;
    } catch (error) {
      navigate('/404');
    }
  };

  useEffect(() => {
    if (count.current === 0 && id) {
      onMount(id);
      count.current += 1;
    }
  }, [id, count.current]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={3}
      width="100%"
      height="100vh"
    >
      <Typography variant="h6">Loading</Typography>
      <LinearProgress sx={{ width: 200 }} />
    </Box>
  );
}

export default RedirectPage;
