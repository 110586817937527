import { Fragment } from 'react';
import { Grid, Pagination, Typography } from '@mui/material';
import { FlexBetween } from '../flex-box';
import ProductCard1 from '../product-cards/ProductCard1';
import { Span } from '../Typography';

// ========================================================

const ProductCard1List = ({ products, results }) => {
  const handlePageChange = (event, page) => {
    // router.push({ query: { ...router.query, page } });
  };

  const { totalResults, includedResults, pagesToRender, currentPage } = results;

  const pageEnd = includedResults * currentPage;
  const pageStart = pageEnd - includedResults;

  const noProducts = products.length < 1;

  return (
    <Fragment>
      <Grid container spacing={3}>
        {noProducts ? (
          <Grid item>
            <Typography variant="body2">No products to show</Typography>
          </Grid>
        ) : (
          products.map(item => (
            <Grid item lg={4} sm={6} xs={12} key={item.id}>
              <ProductCard1 product={item} />
            </Grid>
          ))
        )}
      </Grid>
      <FlexBetween flexWrap="wrap" mt={4}>
        <Span color="grey.600" sx={{ mb: 1 }}>
          Showing {pageStart}-{pageEnd} of {totalResults} Products
        </Span>
        <Pagination
          count={pagesToRender}
          variant="outlined"
          color="primary"
          onChange={handlePageChange}
          page={+currentPage}
          sx={{ mb: 1 }}
        />
      </FlexBetween>
    </Fragment>
  );
};
export default ProductCard1List;
