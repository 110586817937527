import { Fragment, useState } from 'react';
import { Badge, Box, Button, Dialog, Drawer, styled } from '@mui/material';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Clear,
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
} from '@mui/icons-material';
import clsx from 'clsx';
import Icon from '../icons';
import { layoutConstant } from '../../utils/constants';
import Category from '../icons/Category';
import CategoryMenu from '../categories/CategoryMenu';
import { Paragraph } from '../Typography';
import Image from '../BazaarImage';
import MobileMenu from '../navbar/MobileMenu';
import { FlexBetween, FlexBox } from '../flex-box';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
// styled component
export const HeaderWrapper = styled(Box)(({ theme }) => ({
  zIndex: 3,
  position: 'relative',
  height: layoutConstant.headerHeight,
  transition: 'height 250ms ease-in-out',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    height: layoutConstant.mobileHeaderHeight,
  },
}));
const StyledContainer = styled(Container)({
  gap: 2,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const CategoryMenuButton = styled(Button)(({ theme }) => ({
  width: '278px',
  height: '40px',
  backgroundColor: theme.palette.grey[100],
}));

// ==============================================================

// ==============================================================

const Header = ({
  isFixed,
  className,
  searchInput,
  categories,
  navListOpen,
  hideCategories,
  hideSearch,
}) => {
  const theme = useTheme();
  const settings = 'ltr';

  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const downMd = useMediaQuery(theme.breakpoints.down(1150));
  const toggleSearchBar = () => setSearchBarOpen(!searchBarOpen);

  // FOR SMALLER DEVICE
  if (downMd) {
    const ICON_STYLE = {
      color: 'grey.600',
      fontSize: 20,
    };
    return (
      <HeaderWrapper className={clsx(className)}>
        <StyledContainer>
          <FlexBetween width="100%">
            {!hideCategories && (
              <Box flex={1}>
                <MobileMenu categories={categories} />
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <Link href="/">
                <Image height={60} src="/assets/images/logo.png" alt="logo" />
              </Link>
            </Box>

            {!hideSearch && (
              <FlexBox justifyContent="end" flex={1}>
                <Box component={IconButton} onClick={toggleSearchBar}>
                  <Icon.Search sx={ICON_STYLE} />
                </Box>
              </FlexBox>
            )}
          </FlexBetween>

          {!hideSearch && (
            <Drawer
              open={searchBarOpen}
              anchor="top"
              onClose={toggleSearchBar}
              sx={{
                zIndex: 9999,
              }}
            >
              <Box
                sx={{
                  width: 'auto',
                  padding: 2,
                  height: '100vh',
                }}
              >
                <FlexBetween mb={1}>
                  <Paragraph>Search for items</Paragraph>

                  <IconButton onClick={toggleSearchBar}>
                    <Clear />
                  </IconButton>
                </FlexBetween>

                {/* CATEGORY BASED SEARCH FORM */}
                {searchInput}
              </Box>
            </Drawer>
          )}
        </StyledContainer>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper className={clsx(className)}>
      <StyledContainer>
        {/* LEFT CONTENT - LOGO AND CATEGORY */}
        <FlexBox minWidth="170px" alignItems="center">
          <Box mr={2}>
            <Link to="/">
              <HomeIcon />
            </Link>
          </Box>
          {isFixed && !hideCategories && (
            <CategoryMenu categories={categories}>
              <FlexBox color="grey.600" alignItems="center" ml={2}>
                <Button color="inherit">
                  <Category fontSize="small" color="inherit" />
                  <KeyboardArrowDown fontSize="small" color="inherit" />
                </Button>
              </FlexBox>
            </CategoryMenu>
          )}
          {!isFixed && !hideCategories && (
            <CategoryMenu
              open={navListOpen}
              categories={categories}
              flex="1 0 0"
            >
              <CategoryMenuButton variant="text">
                <Category fontSize="small" />
                <Paragraph
                  fontWeight="600"
                  textAlign="left"
                  flex="1 1 0"
                  ml={1.25}
                  color="grey.600"
                >
                  Categories
                </Paragraph>

                {settings === 'ltr' ? (
                  <ChevronRight className="dropdown-icon" fontSize="small" />
                ) : (
                  <ChevronLeft className="dropdown-icon" fontSize="small" />
                )}
              </CategoryMenuButton>
            </CategoryMenu>
          )}
        </FlexBox>
        {/* SEARCH FORM */}
        {!hideSearch && (
          <FlexBox justifyContent="flex-end" flex="1 1 0">
            {searchInput}
          </FlexBox>
        )}
      </StyledContainer>
    </HeaderWrapper>
  );
};
export default Header;
