import { Pagination, Grid, Typography } from '@mui/material';
import { FlexBetween } from '../flex-box';
import ProductCard9 from '../product-cards/ProductCard9';
import { Span } from '../Typography';

// ==========================================================

const ProductCard9List = ({ products, results }) => {
  const handlePageChange = (event, page) => {
    // router.push({ query: { ...router.query, page } });
  };

  const { totalResults, includedResults, pagesToRender, currentPage } = results;

  const pageEnd = includedResults * currentPage;
  const pageStart = pageEnd - includedResults;
  const noProducts = products.length < 1;

  return (
    <div>
      {noProducts ? (
        <Grid item>
          <Typography variant="body2">No products to show</Typography>
        </Grid>
      ) : (
        products.map(item => <ProductCard9 product={item} />)
      )}

      <FlexBetween flexWrap="wrap" mt={4}>
        <Span color="grey.600" sx={{ mb: 1 }}>
          Showing {pageStart}-{pageEnd} of {totalResults} Products
        </Span>
        <Pagination
          count={pagesToRender}
          variant="outlined"
          color="primary"
          onChange={handlePageChange}
          page={+currentPage}
          sx={{ mb: 1 }}
        />
      </FlexBetween>
    </div>
  );
};
export default ProductCard9List;
