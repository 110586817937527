import { useEffect, useRef, useState, useTransition, useCallback } from 'react';
import { Box, MenuItem, TextField, styled, useTheme } from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import TouchRipple from '@mui/material/ButtonBase';
import BazaarMenu from '../BazaarMenu';
import { FlexBox } from '../flex-box';
import { SearchOutlinedIcon, SearchResultCard } from './styled';
import axios from 'axios';
import { debounce } from 'lodash';

const DropDownHandler = styled(FlexBox)(({ theme }) => ({
  whiteSpace: 'pre',
  borderTopRightRadius: 300,
  borderBottomRightRadius: 300,
  borderLeft: `1px solid ${theme.palette.text.disabled}`,
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));
const SearchInputWithCategory = ({ categories }) => {
  const parentRef = useRef();

  const { breakpoints } = useTheme();
  const [_, startTransition] = useTransition();
  const [resultList, setResultList] = useState([]);
  const [searchText, setSearchText] = useState('');

  const [category, setCategory] = useState('*');
  const [categoryTitle, setCategoryTitle] = useState('All Categories');

  const allCategories = [{ id: '*', title: 'All Categories' }, ...categories];

  const handleCategoryChange = cat => {
    setCategory(cat.id);
    setCategoryTitle(cat.title);

    getSuggestions(searchText, cat.id);
  };

  const handleSearch = e => {
    const pressedKey = e.key.toLowerCase();

    if (pressedKey !== 'enter') return;

    const searchValue = e.target.value;

    const queryMap = {
      q: searchValue,
    };

    if (category !== '*') {
      queryMap['category'] = category;
    }

    const queryObject = new URLSearchParams(queryMap);
    const queryString = queryObject.toString();
  };

  const debouncedGetSuggestion = useCallback(
    debounce((searchString, selectedCategory) => {
      getSuggestions(searchString, selectedCategory);
    }, 500),
    []
  );

  const handleSearchText = e => {
    const searchString = e.target.value;
    setSearchText(searchString);
    debouncedGetSuggestion(searchString, category);
  };

  const getSuggestions = (searchString, selectedCategory) => {
    startTransition(async () => {
      if (!searchString) return setResultList([]);

      const categoryToSend = selectedCategory === '*' ? null : selectedCategory;

      const params = {
        categoryId: categoryToSend,
        keyword: searchString,
      };

      const relatedProducts = await axios.get('/api/product', { params });

      setResultList(relatedProducts.data.products.product);
    });
  };

  // ----------------------

  const handleDocumentClick = () => setResultList([]);

  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => window.removeEventListener('click', null);
  }, []);

  // CATEGORY MENU DROPDOWN
  const categoryDropdown = (
    <BazaarMenu
      direction="left"
      sx={{
        zIndex: breakpoints.down('md') ? 99999 : 1502,
      }}
      handler={
        <DropDownHandler
          px={3}
          gap={0.5}
          height="100%"
          color="grey.700"
          bgcolor="grey.100"
          alignItems="center"
          component={TouchRipple}
        >
          {categoryTitle}
          <KeyboardArrowDownOutlined fontSize="small" color="inherit" />
        </DropDownHandler>
      }
    >
      {allCategories.map(item => (
        <MenuItem key={item.id} onClick={() => handleCategoryChange(item)}>
          {item.title}
        </MenuItem>
      ))}
    </BazaarMenu>
  );

  return (
    <Box
      position="relative"
      flex="1 1 0"
      maxWidth="670px"
      mx="auto"
      {...{
        ref: parentRef,
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Searching for..."
        onChange={handleSearchText}
        InputProps={{
          sx: {
            height: 44,
            paddingRight: 0,
            borderRadius: 300,
            color: 'grey.700',
            overflow: 'hidden',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
          },
          endAdornment: categoryDropdown,
          startAdornment: <SearchOutlinedIcon fontSize="small" />,
        }}
        onKeyDown={handleSearch}
      />

      {resultList.length > 0 && (
        <SearchResultCard elevation={2}>
          {resultList.map(item => (
            <a href={`/product/${item.id}`} key={item.id} passHref>
              <MenuItem key={item.id}>{item.title}</MenuItem>
            </a>
          ))}
        </SearchResultCard>
      )}
    </Box>
  );
};

export default SearchInputWithCategory;
